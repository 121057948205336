import Default from 'gatsby-theme-carbon/src/templates/Default';
import {UserContext, handleLogin, isLoggedIn} from '@parallelpublicworks/entitysync';
import { Link, navigate } from "gatsby"
import { Button, Form, TextInput, ToastNotification} from 'carbon-components-react';
import React, {useContext, useEffect, useState} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../util/recaptchaSubmit'
 
function Login({location, setError}) {
  const userContext = useContext(UserContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (typeof userContext[0] !== `undefined` && isLoggedIn(userContext)) {
      const redirectPath = location.state ? location.state.referrer : false;
      navigate(redirectPath ? redirectPath : "/")
    }
  }, [typeof userContext[0] !== `undefined` && isLoggedIn(userContext)])
  if(!location || ! Array.isArray(userContext)){
    return (
      <></>
    )
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    e.persist()
    setIsLoading(true);
    try {
      const valid = await recaptchaSubmit(executeRecaptcha)
      if (!valid) throw new Error("Failed recaptcha")
      const resp = await handleLogin(userContext, e);
      if (!resp) setError(<span>Your password or username may be incorrect. Please try again or click "Forgot password" to reset your password. If you continue having trouble, please contact the site <a href='mailto:support@impactoregon.careers' style={{cursor: 'pointer'}}>administrator</a></span>);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  }
  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <TextInput
        light
        type="text"
        required
        placeHolder="Username"
        name="username"
        id="username"
      />
      <TextInput
        light
        type="password"
        required
        placeHolder="Password"
        name="password"
        id="password"
      />
      <Button type="submit" disabled={isLoading} >Login</Button>
      <div><Link to="/password-reset">Forgot password?</Link></div>
      <div><Link to="/request-access">Request Access</Link></div>
    </Form>
  )
}


export default function LoginPage({pageContext, location}) {
  pageContext = {...pageContext, frontmatter: {title: "Login"}};
  const [error, setError] = useState(null)
  return (
    <Default pageContext={pageContext} location={location}>
      {error && <div className="toast-container">
      <ToastNotification
        caption=""
        iconDescription="Close"
        subtitle=""
        timeout={0}
        title="Failed to login"
        subtitle={error}
        kind="error"
        lowContrast="true"
        onCloseButtonClick={(e) => setError(null)}
      />
      </div>}
      <Login location={location} setError={setError} /> 
    </Default>   
  ) 
}
