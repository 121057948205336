const languages = require('./src/data/languages')
const removeTags = require('./src/util/removeTags')
const isExpired = require('./src/util/isExpired')

module.exports = {
  siteMetadata: {
    title: 'Impact Oregon | Careers that make a difference',
    description: 'Connecting job seekers with careers in the field of developmental disabilities services in Oregon.',
    keywords: 'job seeking, oregon, intellectual and developmental disabilities, careers',
    drupalUrl: 'https://odds-testing-backend.parallelpublicworks.com',
    langs: languages,
    siteUrl: `https://impactoregon.careers`,
  },
  pathPrefix: `/gtc`,
  plugins: [
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Impact Oregon',
        short_name: 'Impact Oregon',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#71AD50',
        display: 'browser',
        icon: './src/images/favicon.svg',
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "G-8HQD35D581",
        ],
      },
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "GTM-T3GLSRV",
        includeInDevelopment: false,
        defaultDataLayer: { platform: "gatsby" },
        routeChangeEventName: "gatsby-route-change",
      },
    },
    'gatsby-plugin-sitemap',
    {
      resolve: 'gatsby-source-drupal',
      options: {
        baseUrl: 'https://odds-testing-backend.parallelpublicworks.com',
        langs: languages
      }
    },
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        isSearchEnabled: false,
        iconPath: './src/images/favicon.svg',
        repository: {
          baseUrl:
            'https://github.com/carbon-design-system/gatsby-theme-carbon',
          subDirectory: '/packages/example',
        },
      },
    },
    {
      resolve: `gatsby-source-drupal-menu-links`,
      options: {
        baseUrl: `https://odds-testing-backend.parallelpublicworks.com`,
        apiBase: `jsonapi`,
        menus: ["main", "secondary", "utility"],
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: 'gatsby-plugin-local-search',
      options: {
        name: 'pages',
        engine: 'lunr',
        query: `
        {
          allNodeCareer {
            nodes {
              drupal_id
              title
              field_career_description {
                processed
              }
              fields {
                slug
              }
              internal {
                type
              }
            }
          }
          allNodeSuccessStory {
            nodes {
              drupal_id
              title
              field_story_description {
                processed
              }
              field_story_job_title
              field_story_intro {
                processed
              }
              field_story_name
              field_story_quote
              field_story_quote_attribution
              fields {
                slug
              }
              internal {
                type
              }
            }
          }
          allNodeJobListing {
            nodes {
              drupal_id
              title
              field_benefits {
                processed
              }
              field_company_name
              field_expired
              field_publish_date
              field_description {
                processed
              }
              field_preferred_qualifications {
                processed
              }
              field_required_qualifications {
                processed
              }
              fields {
                slug
              }
              internal {
                type
              }
              relationships {
                field_category {
                  id
                  name
                }
                field_expiration {
                  name
                }
              }
            }
          }
        }
        `,
        ref: 'id',
        index: ['title', 'body'],
        store: ['id', 'path', 'title', 'type', 'body'],
        normalizer: ({ data }) => {
          let valid_jobs = data.allNodeJobListing && data.allNodeJobListing.nodes ? data.allNodeJobListing.nodes.filter((node) => {
            let autoExpirationName = node.relationships.field_expiration ? node.relationships.field_expiration.name : null;
            let is_expired = isExpired(node, null, autoExpirationName);
            return !is_expired;
          }) : [];
          let norm_jobs = valid_jobs ? valid_jobs.map((node) => {
            let body = ' ';
            body += node.field_description ? removeTags(node.field_description.processed) : '';
            body += ' ';
            body += node.field_benefits ? removeTags(node.field_benefits.processed) : '';
            body += ' ';
            body += node.field_preferred_qualifications ? removeTags(node.field_preferred_qualifications.processed) : '';
            body += ' ';
            body += node.field_required_qualifications ? removeTags(node.field_required_qualifications.processed) : '';
            body += ' ';
            body += node.field_company_name ? node.field_company_name : '';
            body += ' ';
            body += node.relationships.field_category && node.relationships.field_category.length ? node.relationships.field_category.map((cat) => cat.name).join(' ') : '';
            let norm_item = {
              title: node.title,
              type: node.internal.type,
              id: node.drupal_id,
              path: node.fields.slug,
              body: body
            }
            return norm_item;
          }) : [];

          let norm_stories = data.allNodeSuccessStory && data.allNodeSuccessStory.nodes ? data.allNodeSuccessStory.nodes.map((node) => {
            let body = ' ';
            body += node.field_story_intro ? removeTags(node.field_story_intro.processed) : '';
            body += ' ';
            body += node.field_story_description ? removeTags(node.field_story_description.processed) : '';
            body += ' ';
            body += node.field_story_job_title ? node.field_story_job_title : '';
            body += ' ';
            body += node.field_story_name ? node.field_story_name : '';
            body += ' ';
            body += node.field_story_quote ? node.field_story_quote : '';
            body += ' ';
            body += node.field_story_quote_attribution ? node.field_story_quote_attribution : '';
            let norm_item = {
              title: node.title,
              type: node.internal.type,
              id: node.drupal_id,
              path: node.fields.slug,
              body: body
            }
            return norm_item;
          }) : [];
          let norm_careers = data.allNodeCareer && data.allNodeCareer.nodes ? data.allNodeCareer.nodes.map((node) => {
            let body = node.field_career_description ? removeTags(node.field_career_description.processed) : '';
            let norm_item = {
              title: node.title,
              type: node.internal.type,
              id: node.drupal_id,
              path: node.fields.slug,
              body: body
            }
            return norm_item;
          }) : [];
          let norm_array = [...norm_jobs, ...norm_stories, ...norm_careers];
          return norm_array;
        }
      },
    },
  ],
};
